import { useState } from "react";
import { Container, Header } from "../components";
const productList = [
  {
    image: "/assets/demo-watch.png",
    name: "Perpetual 31mm Silver",
    brandName: "Rolex Oyster",
    price: 6059.71,
    sale: true,
  },
  {
    image: "/assets/m278271-0016_modelpage_front_facing_landscape.png",
    name: "Perpetual Datejust Lady 31",
    brandName: "Rolex Oyster",
    price: 4549.23,
    sale: true,
  },
  {
    image:
      "/assets/m278240-0014_modelpage_front_facing_landscape_4-14-2023.png",
    name: "Perpetual Datejust Lady 28",
    brandName: "Rolex Oyster",
    price: 6066.64,
    sale: true,
  },
];

const categoryList = [
  {
    image: "/assets/m124060-0001_modelpage_front_facing_landscape.png",
    name: "SUBMARINER",
    sale: true,
  },
  {
    image: "/assets/m126710blnr-0003_modelpage_front_facing_landscape.png",
    name: "GMT-MASTER II",
    sale: false,
  },
  {
    image: "/assets/m126655-0002_modelpage_front_facing_landscape.png",
    name: "YACHT-MASTER",
    sale: true,
  },
  {
    image: "/assets/m136660-0003_modelpage_front_facing_landscape.png",
    name: "DEEPSEA",
    sale: false,
  },
];

const contactUs = () => {
  window.open("mailto:info@richdragon.io", "_self");
};

export const Home = (props: any) => {
  const [currentCollection, setCurrentCollection] = useState(0);

  return (
    <Container showNav={true}>
      <Header />

      {/* new arrivals */}
      <div className="absolute ml-[50%] mr-[50%] translate-x-[-50%] flex flex-col items-center justify-center mt-[20%] md:mt-[7%] w-full">
        <div className="h-[1px] w-[50px] bg-black mb-[10px] rounded-md" />
        <p className="text-[12px] tracking-[0.2em]">NEW ARRIVALS</p>
      </div>

      {/* promotion product */}
      <div className="flex flex-col">
        <div className="flex flex-row flex-1">
          {/* left */}
          <div className="flex h-[500px] md:h-[650px] flex-1 justify-center">
            <div className="mt-[65%] md:mt-[35%] lg:mt-[25%] flex flex-col items-center">
              <p className="text-[36px] md:text-[48px] tracking-[0.2em] font-semibold">
                Rolex Oyster
              </p>
              <p className="text-[10px] tracking-wide text-slate-600">
                Perpetual 31mm
              </p>
              {/* price */}
              <div className="mt-[20px]">
                <p>€6,066.64</p>
              </div>

              {/* button */}
              <div className="flex flex-col md:flex-row mt-[40px] justify-center items-center">
                <div className="w-[120px] h-[40px] bg-gray-300 rounded-sm flex justify-center items-center">
                  <p className="text-[10px]">Discovery</p>
                </div>
                <div
                  className="w-[150px] h-[50px] bg-black rounded-sm shadow-lg shadow-slate-700 flex justify-center items-center cursor-pointer"
                  onClick={() => contactUs()}
                >
                  <p className="text-[10px] text-white">Contact Us to Buy</p>
                </div>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="flex bg-primary h-[500px] md:h-[650px] flex-1 justify-center">
            <div className="">
              <img
                className="relative h-full"
                src="/assets/800x.png"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>

      {/* product list */}
      <div className="flex flex-row w-full mt-[40px] px-[10px]">
        {productList.map((product, i) => {
          const { name, image, sale, price, brandName } = product;

          return (
            <div key={i} className="flex flex-col flex-1 items-center relative">
              {sale && (
                <div className="w-[20px] h-[50px] absolute top-[12%] left-0 bg-black flex items-center">
                  <p className="[writing-mode:vertical-lr] text-white">Sale</p>
                </div>
              )}
              <img
                src={`${image}`}
                className="w-[200px] h-[200px] object-cover"
                alt="img"
              />
              <p className="tracking-widest mt-[20px] text-[12px] font-medium">
                {name}
              </p>
              <p className="tracking-widest mt-[5px] text-[10px]">
                {brandName}
              </p>
              <p className="tracking-widest mt-[20px] text-[10px]">€{price}</p>

              <div
                className="mt-[20px] w-[120px] h-[35px] bg-black rounded-sm shadow-lg shadow-slate-700 flex justify-center items-center cursor-pointer"
                onClick={() => contactUs()}
              >
                <p className="text-[10px] text-white">Buy Now</p>
              </div>
            </div>
          );
        })}
      </div>

      {/* special product */}
      <div className="flex flex-row mt-[40px]">
        {/* left */}
        <div className="flex bg-primary h-[300px]  md:h-[500px] flex-1 justify-center relative">
          <div
            className="absolute top-[20%] left-[20%] md:left-[30%] w-[90%] h-[90%]"
            style={{
              backgroundImage: `url(/assets/special_product.jpg)`,
              backgroundSize: "cover",
            }}
          />
        </div>

        {/* right */}
        <div className="flex flex-col flex-1 mt-[10%] items-center">
          <div className="h-[1px] w-[50px] bg-gray-400 mb-[10px] rounded-md" />
          <p className="text-[10px] tracking-wide text-gray-400">
            Special Product
          </p>
          <p className="text-[10px] tracking-wide	mt-[40px] text-gray-400">
            Great Watch, Better Life
          </p>

          <p className="text-[18px] tracking-wide mt-[20px] text-black font-semibold">
            IWC
          </p>

          <p className="text-[18px] tracking-wide text-black font-semibold">
            moods
          </p>

          <div
            className="mt-[20px] w-[120px] h-[40px] bg-slate-50 rounded-sm flex justify-center items-center cursor-pointer"
            onClick={() => contactUs()}
          >
            <p className="text-[10px]">Discover</p>
          </div>
        </div>
      </div>

      <div className="mt-[80px] flex flex-col items-center">
        <div className="h-[1px] w-[50px] bg-gray-400 mb-[10px] rounded-md" />
        <p className="text-[10px] tracking-wide text-gray-400">COLLECTION</p>
        {/* product list */}
        <div className="grid mt-[40px] w-[80%] grid-cols-2 md:grid-cols-4">
          {categoryList.map((product, i) => {
            const { name, image, sale } = product;

            return (
              <div
                key={i}
                className={`flex flex-col flex-1 items-center mr-[20px] relative p-[10px] cursor-pointer ${
                  currentCollection === i && "shadow-xl"
                }`}
                onMouseEnter={() => setCurrentCollection(i)}
                onClick={() => {setCurrentCollection(i); contactUs()}}
              >
                {sale && (
                  <div className="w-[20px] h-[50px] absolute top-[12%] left-0 bg-black flex items-center">
                    <p className="[writing-mode:vertical-lr] text-white">
                      Sale
                    </p>
                  </div>
                )}
                {currentCollection === i && (
                  <div className="h-[2px] w-[100px] bg-black mb-[10px] rounded-md" />
                )}
                <img src={`${image}`} className="w-[300px] h-[300px] object-cover" alt="img" />
                <p className="tracking-widest mt-[20px] text-[12px] font-medium">
                  {name}
                </p>
                {currentCollection === i && (
                  <>
                    <div className="h-[1px] w-[100px] bg-gray-400 mb-[10px] rounded-md mt-[10px]" />
                    <p className="text-[10px]">Discover</p>
                  </>
                )}
              </div>
            );
          })}
        </div>
      </div>

      {/* footer */}
      <div className="flex flex-row p-[20px] mt-[20px] border-t-[1px]">
        {/* email */}
        <div className="flex-1 flex flex-row justify-start items-center">
          <p className="text-[10px] ml-[12px]">info@richdragon.io</p>
        </div>

        <div className="flex-1" />
      </div>
    </Container>
  );
};
