export const Header = (props: any) => {
  return (
    <div className="absolute bg-transparent w-full px-[30px] py-[30px] flex flex-row align-center items-center z-[100]">
      <div className="flex flex-row align-center items-center flex-1 ">
        RD Luxury
      </div>
    </div>
  );
};

