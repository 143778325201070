import axios from "axios";
import * as Configs from "../config";
import { generalError } from ".";
import { openSuccessNotification } from "src/components";
import { history } from ".";
import { md5 } from "js-md5";
import { removeToken, setToken } from "../utils";

export class AuthStore {
  async login(email: string, password: string) {
    const url = `${Configs.API_URL}/v1/auth`;
    try {
      const result: any = await axios.post(url, {
        email,
        password: md5(password),
      });
      if (!result || !result["data"]["data"]) return generalError;

      const { token, firstName, lastName } = result["data"]["data"];

      setToken(token);
      // set the admin name
      setToken(`${firstName} ${lastName}`, "name");

      return true;
    } catch (e) {
      return false;
    }
  }

  logout() {
    openSuccessNotification("登出成功！");
    removeToken();
    history.push("/");
    return;
  }
}

export const STORE_AUTH = "authStore";
